.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



.email-link {
  color: #FFD700;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 18px;
}

.email-link:hover {
  color: white;
  text-decoration: none;
}

.email-link:hover .email-icon {
  color: white;
  transform: scale(1.1); 
}


#particles-js {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.resume-container {
  margin-top: 5rem; /* Adjust margin as needed */
  padding-bottom: 5rem;
  margin-right: 1rem;
}

.resume-icon {
  width: 1.5rem; /* Adjust the size of SVG icons */
  height: 1.5rem;
  margin-right: 0.5rem; /* Add some space to the right of icons */
}


.projects-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Adjust the gap between cards as needed */
}

.project-card {
  flex: 1 1 calc(33.33% - 20px); /* Adjust the percentage and gap as needed */
  max-width: 400px; /* Limit the maximum width of each card */
}

.card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card-img-top {
  max-width: 100%;
  height: auto;
}

.skill-dropdown {
  text-align: left; /* Align dropdown content to the left */
}

.skill-dropdown button {
  display: block;
  width: 100%;
  text-align: left;
  padding: 0.5rem 1rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.skill-dropdown button:hover {
  background-color: #f8f9fa; /* Hover color for buttons */
}

.skill-dropdown .dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.skill-dropdown button:focus + .dropdown-content {
  display: block;
}

.custom-button {
  background-color: grey; /* Change this to your desired color */
  color: white; /* Change the text color accordingly */
}

/* App.css */

/* ... your existing CSS rules ... */

.announcement-item {
  margin: 20px 0;
  padding-left: -40px;

}

.announcement-sublist::before {
  padding-left: 20px;
  font-size: 18px;
  line-height: 1.5;

}



.announcement-sublist li {
  margin-bottom: 5px;
  list-style: none; /* Remove default bullet point */
  position: relative; /* Create a position context for the custom bullet point */
}

.announcement-sublist li::before {
  content: "\2022"; /* Custom bullet point character */
  position:static;
  left: 20px; /* Adjust this value to control the spacing */
  margin-right: 15px;
}